var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.content && _vm.checkShow)?_c('div',{staticClass:"detail-content margin-content",staticStyle:{"padding-left":"0"},attrs:{"id":"disable-right-click"}},[_c('div',{staticClass:"dc-header"},[_c('b-container',{staticClass:"d-flex align-items-center font-title",attrs:{"fluid":""}},[_c('router-link',{attrs:{"to":{ name: _vm.$route.params.shopId ? 'library' : 'library domain' }}},[_c('div',[_vm._v("コンテンツ")])]),_c('b-icon',{staticClass:"mx-3",attrs:{"icon":"chevronRight"}}),_c('router-link',{attrs:{"to":{
          name: _vm.$route.params.shopId ? 'library' : 'library domain',
          params: { keyword: _vm.keywordFilter },
        }}},[_c('div',[_vm._v("絞り込み検索結果")])]),_c('b-icon',{staticClass:"mx-3",attrs:{"icon":"chevronRight"}}),_c('router-link',{attrs:{"to":{ name: '' }}},[_c('div',[_vm._v("目次")])])],1)],1),_c('div',{staticClass:"dc-content"},[_c('b-container',{attrs:{"fluid":""}},[(_vm.content && Object.keys(_vm.content).length > 0)?_c('b-row',[_c('div',{staticClass:"ml-3 version-pc"},[_c('div',{staticClass:"dc-content-img",class:{
              'no-img d-flex align-items-center justify-content-center':
                !_vm.content.image_url,
            }},[(_vm.content.image_url)?_c('img',{staticClass:"card-img-top",attrs:{"src":`${_vm.urlBackend}/storage/${_vm.content.image_url}`,"alt":"Image"}}):_c('div',{staticClass:"h5 text-dark"},[_vm._v(_vm._s(_vm.content.title))])]),_c('div',{staticClass:"dc-content-detail d-flex flex-column"},[_c('div',{staticClass:"dc-content-detail-title mt-3"},[_vm._v(" "+_vm._s(_vm.content.title)+" ")]),_c('div',{staticClass:"dc-content-detail-author d-flex align-items-center mt-3"},[_c('div',{staticClass:"img-author-library"},[_c('img',{attrs:{"src":_vm.content.avatar
                      ? `${_vm.urlBackend}/${_vm.content.avatar}`
                      : _vm.image_url_default,"alt":""}})]),_c('div',{staticClass:"nickname-author mx-3"},[_c('router-link',{attrs:{"to":{
                    name: _vm.$route.params.shopId
                      ? 'author detail'
                      : 'author detail domain',
                    params: {
                      shopId: _vm.shop_id,
                      id: _vm.content.user_id,
                    },
                  }}},[_vm._v(" "+_vm._s(_vm.content.last_name + " " + _vm.content.first_name)+" ")])],1)]),_c('div',{staticClass:"dc-content-detail-category mt-3 mb-3"},[_vm._v(" "+_vm._s(_vm.content.category_name)+" ")])])]),_c('div',{staticClass:"ml-3 version-sp d-flex"},[_c('div',{staticClass:"dc-content-img",class:{
              'no-img d-flex align-items-center justify-content-center':
                !_vm.content.image_url,
            }},[(_vm.content.image_url)?_c('img',{staticClass:"card-img-top",attrs:{"src":`${_vm.urlBackend}/storage/${_vm.content.image_url}`,"alt":"Image"}}):_c('div',{staticClass:"h5 text-dark"},[_vm._v(" "+_vm._s(_vm.content.title)+" ")])]),_c('div',{staticClass:"pc-only",staticStyle:{"margin-left":"10px","width":"50%"}},[_c('div',{staticClass:"dc-content-detail-title mt-3"},[_vm._v(" "+_vm._s(_vm.content.title)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.content.description)+" ")])])]),_c('div',{staticClass:"sp-only",staticStyle:{"margin-left":"20px","width":"50%"}},[_c('div',{staticClass:"dc-content-detail-title mt-3"},[_vm._v(" "+_vm._s(_vm.content.title)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.content.description)+" ")])]),_c('div',{staticClass:"mt-3 ml-3 version-sp"},[_vm._v(" ご覧になりたい目次を以下から選択してください ")]),_c('div',{staticClass:"content-width"},[_c('div',{staticClass:"dc-content-contents",staticStyle:{"padding-bottom":"20px"}},[_c('div',{staticStyle:{"margin-left":"40px","border-top":"1px solid rgb(230, 230, 230)","border-bottom":"1px solid rgb(230, 230, 230)","width":"550px"}},_vm._l((_vm.dataCata),function(item,index){return _c('div',{key:index,staticClass:"contents-list-item position-relative",class:{ onlyItem: _vm.dataCata.length < 2 }},[_c('div',{staticStyle:{"margin-left":"7px"}},[_c('div',{staticStyle:{"width":"70px"}}),_c('div',[(item.title)?_c('div',{staticClass:"customTitleChapter",class:{ noBorderTop: index === 0 },staticStyle:{"margin-left":"70px"}},[_c('span',[_vm._v(_vm._s(item.title))])]):_vm._e(),(item.page)?_c('div',{class:`page-chaper-` + index},_vm._l((item.page),function(ele,index){return _c('div',{key:index,staticClass:"textName customTitlePage d-flex",on:{"click":function($event){return _vm.showDetailpage(
                            ele,
                            item.catalogue_id,
                            item.position
                          )}}},[_c('div',{staticClass:"pageBorder"},[(ele.is_read)?_c('div',{staticClass:"icon-check"},[_c('b-icon',{attrs:{"icon":"check"}})],1):_c('div',{staticClass:"icon-check"},[_c('img',{staticClass:"icon-no-read",attrs:{"src":_vm.imgNoRead,"alt":""}})]),_c('div',{staticStyle:{"padding-left":"60px"}},[_vm._v(" "+_vm._s(ele.page_title)+" ")])])])}),0):_c('div',{staticClass:"hoverTittlePage"},[_c('div',{staticClass:"textName customTitlePage d-flex",class:{ onlyItem: _vm.dataCata.length < 2 },on:{"click":function($event){return _vm.showDetailpage(item, null, item.position)}}},[_c('div',{staticClass:"pageBorder"},[(item.is_read)?_c('div',{staticClass:"icon-check"},[_c('b-icon',{attrs:{"icon":"check"}})],1):_c('div',{staticClass:"icon-check"},[_c('img',{staticClass:"icon-no-read",attrs:{"src":_vm.imgNoRead,"alt":""}})]),_c('div',{},[_vm._v(" "+_vm._s(item.page_title)+" ")])])])])])]),_c('div',{staticClass:"line-catalogue-above",style:(index === 0
                      ? { background: '#fff' }
                      : { background: '#d1d1d1' })}),_c('div',{staticClass:"line-catalogue-below",style:(index === _vm.dataCata.length - 1
                      ? { background: '#fff' }
                      : { background: '#d1d1d1' })})])}),0)])])]):_vm._e()],1)],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }